import * as THREE from 'three';
import { NaverPoint } from './NaverMapManager';
import wkx from 'wkx'
import { ConverterLayer } from './DataTypes';

const OrbitControls = require('three-orbitcontrols');
const { reproject } = require('reproject');

export class SceneManager {
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  orthoCamera: THREE.OrthographicCamera;
  orthoControl: any;

  perspectiveCamera: THREE.PerspectiveCamera;
  perspectiveControl: any;

  renderCamera: THREE.Camera;
  mainControl: any;
  canvasElement: HTMLCanvasElement;

  constructor() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
    this.scene = new THREE.Scene();
    this.orthoCamera = new THREE.OrthographicCamera(-50, 50, 50, -50, 0.001, 10000);
    this.orthoCamera.position.set(0, 0, 1);
    this.orthoControl = new OrbitControls(this.orthoCamera, this.renderer.domElement);

    this.perspectiveCamera = new THREE.PerspectiveCamera(60, 3 / 4, 0.1, 1000);
    this.perspectiveControl = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);

    this.perspectiveCamera.position.set(0, 0, -10);
    this.perspectiveCamera.lookAt(new THREE.Vector3(0, 0, 0));
    this.orthoControl.enableRotate = false;

    this.renderCamera = this.orthoCamera;
    this.canvasElement = this.renderer.domElement;
    this.mainControl = this.orthoControl;
  }

  SceneInit = () => {
    this.renderer.setSize(window.innerWidth * 0.9, window.innerHeight - 70);
    this.renderer.setClearColor(new THREE.Color(0.2, 0.2, 0.2), 1);

    this.orthoControl.screenSpacePanning = true;
    this.orthoControl.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      MIDDLE: THREE.MOUSE.MIDDLE,
      RIGHT: THREE.MOUSE.LEFT
    }

    this.perspectiveControl.screenSpacePanning = true;
    this.perspectiveControl.mouseButtons = {
      LEFT: THREE.MOUSE.RIGHT,
      MIDDLE: THREE.MOUSE.MIDDLE,
      RIGHT: THREE.MOUSE.LEFT
    }
  }

  switchRenderCamera = (is2D: boolean) => {
    if (is2D) {
      this.renderCamera = this.orthoCamera;
      // this.orthoControl.enableRotate = false;
      this.orthoControl.enabled = true;
      this.perspectiveControl.enabled = false;
    }
    else {
      this.renderCamera = this.perspectiveCamera;
      // this.orthoControl.enableRotate = true;
      this.orthoControl.enabled = false;
      this.perspectiveControl.enabled = true;
    }
    // this.orthoControl.object = this.renderCamera;
    // console.log(this.perspectiveControl);
  }

  render = () => {
    this.renderer.render(this.scene, this.renderCamera);
  }

  addObjectToScene = (object: THREE.Object3D) => {
    this.scene.add(object);
  }

  CameraFrustumResize = (frustumSize: number, aspect: number) => {
    this.orthoCamera.left = -frustumSize;
    this.orthoCamera.right = frustumSize;
    this.orthoCamera.top = frustumSize / aspect;
    this.orthoCamera.bottom = -frustumSize / aspect;
    this.orthoCamera.updateProjectionMatrix();

    this.perspectiveCamera.aspect = aspect;
    this.perspectiveCamera.updateProjectionMatrix();
  }

  getScreenCapture = (width: number, height: number, bbox: THREE.Box3) => {
    let clearAlpha = this.renderer.getClearAlpha();
    this.renderer.setClearAlpha(0);
    let exPos = this.orthoCamera.position.clone();
    let exTarget = this.orthoControl.target.clone();
    let exZoom = this.orthoCamera.zoom;

    let bboxCenter = new THREE.Vector3();
    let bboxSize = new THREE.Vector3()
    bbox.getCenter(bboxCenter);
    bbox.getSize(bboxSize);

    this.orthoCamera.zoom = 1;
    this.orthoCamera.position.set(bboxCenter.x, bboxCenter.y, 1);
    this.orthoControl.target.set(bboxCenter.x, bboxCenter.y, bboxCenter.z);

    if (bboxSize.x > bboxSize.y) {
      let f = bboxSize.x * 0.55;
      this.orthoCamera.right = f;
      this.orthoCamera.left = -f;
      this.orthoCamera.top = f * (height / width);
      this.orthoCamera.bottom = -f * (height / width);
    }
    else {
      let f = bboxSize.y * 0.55;
      this.orthoCamera.top = f;
      this.orthoCamera.bottom = -f;
      this.orthoCamera.right = f * (width / height);
      this.orthoCamera.left = -f * (width / height);
    }
    console.log(this.orthoCamera.top);
    this.orthoCamera.updateProjectionMatrix();
    this.renderer.setSize(width, height);

    this.renderer.render(this.scene, this.orthoCamera);

    let imgData = this.renderer.domElement.toDataURL("image/png");
    imgData = imgData.slice(22);

    this.renderer.setClearAlpha(clearAlpha);
    this.renderer.render(this.scene, this.orthoCamera);

    this.orthoCamera.zoom = exZoom;
    this.orthoCamera.position.set(exPos.x, exPos.y, exPos.z);
    this.orthoControl.target.set(exTarget.x, exTarget.y, exTarget.z);

    return Buffer.from(imgData, 'base64');
  }

  setRendererAlpha = (value: number) => {
    this.renderer.setClearAlpha(value);
  }

  getControl = () => {
    return this.mainControl;
  }

  getScreenPosition(point: THREE.Vector3, offset: ClientRect | DOMRect) {
    let mvp = this.orthoCamera.projectionMatrix.clone().multiply(this.orthoCamera.matrixWorldInverse);
    let np = point.clone().applyMatrix4(mvp);
    let rendererSize = new THREE.Vector2();
    this.renderer.getSize(rendererSize);
    let nx = (np.x + 1) * 0.5 * rendererSize.x;
    let ny = (1 - (np.y + 1) * 0.5) * rendererSize.y;
    return new THREE.Vector2(nx + offset.left, ny + offset.top);
  }

  getLatLonPosition(point: THREE.Vector3, proj: any, offset: ClientRect | DOMRect) {
    let mvp = this.orthoCamera.projectionMatrix.clone().multiply(this.orthoCamera.matrixWorldInverse);
    let np = point.clone().applyMatrix4(mvp);
    let rendererSize = new THREE.Vector2();
    this.renderer.getSize(rendererSize);
    let nx = (np.x + 1) * 0.5 * rendererSize.x;
    let ny = (1 - (np.y + 1) * 0.5) * rendererSize.y;
    return proj.fromPageXYToCoord(NaverPoint(nx + offset.left, ny + offset.top));
  }

  getAreaWKTFile = (layers: ConverterLayer[], sceneOffset: ClientRect | DOMRect, mapOffset: THREE.Vector2, proj: any, objectMatrix: THREE.Matrix4) => {
    const projFrom = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const projTo = "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs";

    if (layers.length === 0) {
      return [];
    }

    let multiPolygon: any[] = [];

    layers.forEach(l => {
      l.polygons.forEach(bp => {
        if (!bp.motherPolygon) {
          let bodyPolygon: any[] = [];
          let polygon: any[] = [];
          bp.vertices.forEach(v => {
            let pos = this.getLatLonPosition(v.clone(), proj, sceneOffset);
            bodyPolygon.push([pos.x + mapOffset.x, pos.y + mapOffset.y]);
          })

          polygon.push(bodyPolygon);
          l.polygons.forEach(hp => {
            if (hp.motherPolygon === bp) {
              let hole: any[] = [];
              hp.vertices.forEach(v => {
                let pos = this.getLatLonPosition(v.clone(), proj, sceneOffset);
                hole.push([pos.x + mapOffset.x, pos.y + mapOffset.y]);
              })
              polygon.push(hole);
            }
          })

          let geoJSON = {
            type: 'Polygon',
            coordinates: polygon,
          }
          
          multiPolygon.push(wkx.Geometry.parseGeoJSON(reproject(geoJSON, projFrom, projTo)).toWkt());
        }
      })
    })

    return multiPolygon;
  }

  getPointToMap = (point: THREE.Vector3, sceneOffset: ClientRect | DOMRect, mapOffset: THREE.Vector2, proj: any) => {
    const projFrom = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const projTo = "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs";

    let tmPoint = this.getLatLonPosition(point, proj, sceneOffset);

    let geoJSON = {
      type: 'Polygon',
      coordinates: [tmPoint.x + mapOffset.x, tmPoint.y + mapOffset.y],
    }
    let output = reproject(geoJSON, projFrom, projTo);

    return new THREE.Vector3(output.coordinates[0], output.coordinates[1], 0);
  }
}