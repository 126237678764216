import DxfParser from '@teneleven/dxf-parser/src';
import { ConverterLayer, entityType } from './DataTypes';
import * as THREE from '@teneleven/three';
import _ from 'lodash';
import iconv from 'iconv-lite';
import * as turf from '@turf/turf';
import wkx from 'wkx';

const earcut = require('earcut');
const lineGeometry = require('@teneleven/three/examples/js/lines/LineGeometry');
const lineMaterial = require('@teneleven/three/examples/js/lines/LineMaterial');
const Line2 = require('@teneleven/three/examples/js/lines/Line2');

export function makePolygon(vertices: THREE.Vector3[], color: THREE.Color, type: entityType) {
  let vertsArea = new Array<number>();
  vertices.forEach(v => {
    vertsArea.push(v.x, v.y);
  });

  let vertsGeo = new Array<number>();
  let colorGeo = new Array<number>();
  vertices.forEach(v => {
    vertsGeo.push(v.x, v.y, v.z);
    colorGeo.push(color.r, color.g, color.b);
  })

  let area = 0;
  if (vertices.length > 2) {
    let triangles = earcut(vertsArea);
    for (let i = 0; i < triangles.length; i += 3) {
      area += new THREE.Triangle(vertices[triangles[i]], vertices[triangles[i + 1]], vertices[triangles[i + 2]]).getArea();
    }
  }

  let geometry = new lineGeometry();
  geometry.setPositions(vertsGeo);
  geometry.setColors(colorGeo);
  let matLine = new lineMaterial({
    linewidth: 4, // in pixels
    vertexColors: THREE.VertexColors,
    dashed: true,
    dashSize: 1,
    gapSize: 1,
    dashScale: 0.5,
  });
  matLine.resolution.set(window.innerWidth, window.innerHeight);
  let line = new Line2(geometry, matLine).computeLineDistances();
  switchLineDashedState(matLine, matLine.dashed);

  return ({
    lineMesh: line,
    vertices: vertices,
    type: type,
    selected: false,
    area: area,
  })
}

export function switchLineDashedState(material: any, dashed: boolean) {
  material.dashed = dashed;

  if (material.dashed) {
    material.defines.USE_DASH = "";
  }
  else {
    delete material.defines.USE_DASH;
  }
  material.needsUpdate = true;
}

export function checkHolePolygon(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      l.polygons.forEach(hp => {
        if (p !== hp) {
          if (p.area > hp.area) {
            if (polygonInOtherPolygon(p.vertices, hp.vertices)) {
              hp.motherPolygon = p;
              hp.area *= -1;
            }
          }
        }
      });
    });
  });
}

function polygonInOtherPolygon(vertices1: THREE.Vector3[], vertices2: THREE.Vector3[]) {
  let coord: number[][] = [];
  vertices1.forEach(v => {
    coord.push([v.x, v.y]);
  })
  var polygon = turf.polygon([coord]);
  let v2inv1 = false;

  vertices2.forEach(v => {
    v2inv1 = turf.inside([v.x, v.y], polygon);
  });

  return v2inv1;
}

export function dataParsing(data: string) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let entities = dxf.entities;

  let scale = 0.001;
  let layers = dxf.tables['layer'].layers;
  let layerArray = new Array<ConverterLayer>();

  //get layers
  _.forEach(dxf.tables['layer'].layers, (v, k) => {
    if (k !== '0')
      layerArray.push({
        name: v.name,
        color: v.color,
        colorIndex: v.colorIndex,
        frozen: v.frozen,
        visible: v.visible,
        polygons: [],
        selected: false,
      });
  });

  let bbox = new THREE.Box3();
  entities.forEach(e => {
    if (e.vertices) {
      e.vertices.forEach(v => {
        bbox.expandByPoint(new THREE.Vector3(v.x, v.y, v.z));
      });
    }
  })
  let center = new THREE.Vector3();
  bbox.getCenter(center);
  center.multiplyScalar(scale);

  console.log(layerArray);
  //get polygons
  entities.forEach(e => {
    let verts = [];
    let l = layerArray.find(layer => layer.name === e.layer);

    switch (e.type) {
      case entityType.LWPOLYLINE:
      case entityType.POLYLINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(e.vertices[j].x * scale - center.x, e.vertices[j].y * scale - center.y, e.vertices[j].z * scale - center.z));
        }
        verts.push(new THREE.Vector3(e.vertices[0].x * scale - center.x, e.vertices[0].y * scale - center.y, e.vertices[0].z * scale - center.z));
        if (l)
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type));
        break;

      case entityType.LINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(e.vertices[j].x * scale - center.x, e.vertices[j].y * scale - center.y, e.vertices[j].z * scale - center.z));
        }
        if (l)
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type));
        break;

      default:
        console.log(e);
        break;
    }
  });
  checkHolePolygon(layerArray);
  return layerArray;
}

export async function asyncFileRead(fl: FileList) {
  let reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    if (fl[0]) {
      reader.readAsArrayBuffer(fl[0]);
      reader.onload = function () {
        // @ts-ignore 
        let data = iconv.decode(Buffer.from(this.result), 'utf-8');
        resolve(data);
      };
    }
  });
}

export function getBoundaryLine(polygons: any[]) {
  let boundaryLines: any[] = [];
  polygons.forEach(wkt => {
    const gj = wkx.Geometry.parse(wkt).toGeoJSON();
    const p = {
      "type": "Feature",
      "geometry": gj,
    } as any;

    const b = turf.buffer(p, -4, { units: "meters" });

    boundaryLines.push(wkx.Geometry.parseGeoJSON(b.geometry as any).toWkt());
  });
  return boundaryLines;
}

