import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import './css/BuildingTypeConverter.scss';
import '@teneleven/protocols-ts-web';

import { House, ConverterLayer, areaType, Core, buildingPlanStruct, areaProportion, resultLocation } from './DataTypes';
import * as MeshMaker from './MeshMaker';
import { dataParsing, asyncFileRead, switchLineDashedState, getBoundaryLine } from './FileParser';
import { NaverMapManager, NaverPoint, NaverLatLng, NaverPolygon } from './NaverMapManager';
import { SceneManager } from './SceneManager';
import { saveDataToS3, saveDataToDynamoDB, getBuildingTypeJson, getAddressByProjectSite } from './DBManager';

const uuid4 = require('uuid/v4');

export interface SceneProps {

}

export interface SceneState {
  layers: ConverterLayer[];
  handle: number;
  is2D: boolean;
  layerListHidden: boolean;
  areaListHidden: boolean;
  buildingListHidden: boolean;
  houseListHidden: boolean;
  screenWidth: number;
  screenHeight: number;
  canvasAlpha: number;
  address: string;
}

interface selectHouse {
  building: BuildingType;
  house: House | Core;
  part: LayerType;
}

enum LayerType {
  site = 'site',
  road = 'road',
  vacancyOutsize = 'vacancy outsize',
  vacancyInside = 'vacancy inside',
  wall = 'wall',
  window = 'window',
  core = 'core',
  house = 'house',
  none = 'none'
}

export interface BuildingType {
  name: string;
  houses: House[];
  cores: Core[];
  showList: boolean;
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    handle: 0,
    is2D: true,
    areaListHidden: false,
    layerListHidden: true,
    buildingListHidden: false,
    houseListHidden: false,
    screenWidth: window.innerWidth * 0.9,
    screenHeight: window.innerHeight - 70,
    canvasAlpha: 1,
    address: '마포구 월드컵북로 396',
  };

  mount: HTMLDivElement | null = null;

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  Buildings = new Array<BuildingType>();

  siteLayers = new Array<ConverterLayer>();
  roadLayers = new Array<ConverterLayer>();
  vacancyOutsideLayers = new Array<ConverterLayer>();
  vacancyInsideLayers = new Array<ConverterLayer>();

  naverPolygon: any;
  naverMapProj: any;
  basePosition: any;

  selectingLayer = {} as selectHouse;

  addType = LayerType.site;

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();
    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.resizeCanvasResolution);

    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
  }

  componentDidUpdate = (previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let aspect = this.state.screenWidth / this.state.screenHeight;

      let frustumSize = 0;
      if (this.state.canvasAlpha === 1) {
        frustumSize = this.sceneManager.orthoCamera.right;
      } else {
        let proj = this.mapManager.getProjection();
        let rect = this.mount!.getBoundingClientRect();
        let p1 = proj.fromPageXYToCoord(NaverPoint(0 + rect.left, 0 + rect.top));
        let p2 = proj.fromPageXYToCoord(NaverPoint(1 + rect.left, 0 + rect.top));
        frustumSize = proj.getDistance(p1, p2) * this.state.screenWidth / 2;
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(this.state.screenWidth, this.state.screenHeight);
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth * 0.9,
      screenHeight: window.innerHeight - 70,
    });
  }

  dataInitialize = () => {
    this.siteLayers = [];
    this.roadLayers = [];
    this.vacancyOutsideLayers = [];
    this.vacancyInsideLayers = [];

    this.bbox = new THREE.Box3();

    this.setState({
      layers: [],
    })
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let data = await asyncFileRead(e.target.files!);
    let layer = dataParsing(data);
    this.polygon2DGroup.children = [];

    layer.forEach(l => {
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
      })
    });

    this.bbox.makeEmpty();
    layer.forEach(l => {
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
    });

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(0, 0, 1);
    this.sceneManager.orthoControl.target.set(0, 0, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      layers: layer,
    })
  }

  handled = () => {
    this.setState({
      handle: this.state.handle + 1,
    })
  }

  switchLayerState(l: ConverterLayer) {
    if (l.selected) {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, true);
      });
      l.selected = false;
    }
    else {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, false);
      })
      l.selected = true;
    }
  }


  layerClicked = (layer: ConverterLayer) => {
    if (layer.selected) {
      alert('selected layer!!!!!');
      this.showLayerList(LayerType.none);
      return;
    }

    switch (this.addType) {
      case LayerType.site:
        this.siteLayers.push(layer);
        break;
      case LayerType.road:
        this.roadLayers.push(layer);
        break;
      case LayerType.vacancyOutsize:
        this.vacancyOutsideLayers.push(layer);
        break;
      case LayerType.vacancyInside:
        this.vacancyInsideLayers.push(layer);
        break;
      case LayerType.wall:
        if ((this.selectingLayer.house as House).wall)
          this.switchLayerState((this.selectingLayer.house as House).wall!);
        (this.selectingLayer.house as House).wall = layer;
        MeshMaker.remakeHousePolygons((this.selectingLayer.house as House));
        break;
      case LayerType.window:
        if ((this.selectingLayer.house as House).window)
          this.switchLayerState((this.selectingLayer.house as House).window!);
        (this.selectingLayer.house as House).window = layer;
        MeshMaker.remakeHousePolygons((this.selectingLayer.house as House));
        break;
      case LayerType.core:
        if ((this.selectingLayer.house as Core).core)
          this.switchLayerState((this.selectingLayer.house as Core).core!);
        (this.selectingLayer.house as Core).core = layer;
        MeshMaker.remakeCorePolygons((this.selectingLayer.house as Core));
        break;
      default:
        break;
    }

    this.switchLayerState(layer);
    this.showLayerList(LayerType.none);

    this.handled();
  }

  mouseOverLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, layer: ConverterLayer) => {
    e.currentTarget.style.background = this.mouseOverLayerColor;
    this.state.layers.forEach(l => {
      let visible = false;
      if (l.name === layer.name)
        visible = true;
      l.polygons.forEach(p => {
        p.lineMesh.visible = visible;
      })
    })
  }

  mouseOutLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.currentTarget.style.background = this.baseLayerColor;
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  mouseOverHouseTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, house: House) => {
    e.currentTarget.style.background = this.mouseOverLayerColor;
    this.state.layers.forEach(l => {
      let visible = false;
      if ((house.wall && l.name === house.wall.name) || (house.window && l.name === house.window.name))
        visible = true;
      l.polygons.forEach(p => {
        p.lineMesh.visible = visible;
      })
    })
  }

  mouseOutHouseTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.currentTarget.style.background = this.baseLayerColor;
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  addHouse2Core = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, house: House) => {
    let h = (this.selectingLayer.house as Core).houses.find(element => element.name === house.name);
    if (h) {
      alert('selectd house!!!!!');
    }
    else {
      (this.selectingLayer.house as Core).houses.push(house);
      let l = 0;
      (this.selectingLayer.house as Core).houses.forEach(h => {
        l = Math.max(l, h.level.length);
      });

      (this.selectingLayer.house as Core).level = [];
      (this.selectingLayer.house as Core).levelHeights = [];

      for (let i = 0; i < l; i++) {
        (this.selectingLayer.house as Core).level.push(true);
        (this.selectingLayer.house as Core).levelHeights.push(2.8);
      }
    }

    this.showLayerList(LayerType.none);

    this.handled();
  }

  getPolygonLayerName = (layer: ConverterLayer | null, part: LayerType) => {
    if (layer)
      return layer.name;
    else
      return 'Select a ' + part;
  }

  getAreas = (area: areaType) => {
    let totalArea = 0;

    switch (area) {
      case areaType.vacancyInsideArea:
        this.siteLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        break;

      case areaType.roadArea:
        this.roadLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        break;

      case areaType.vacancyOutsizeArea:
        this.vacancyOutsideLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        break;

      case areaType.openSpaceArea:
        this.vacancyInsideLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        break;

      case areaType.totalBuildingArea:
        this.Buildings.forEach(b => {
          b.houses.forEach(h => {
            totalArea += h.exclusiveArea;
            totalArea += h.serviceArea;
          })

          b.cores.forEach(c => {
            totalArea += c.area;
          })
        })
        break;

      case areaType.totalGroundArea:
        this.Buildings.forEach(b => {
          b.cores.forEach(c => {
            let l = 1;
            let p = 100;
            c.houses.forEach(h => {
              totalArea += h.exclusiveArea * (h.level.length - h.piloti);
              l = Math.max(l, h.level.length);
              p = Math.min(p, h.piloti);
            })
            totalArea += c.area * (l - p);

            if (p > 0)
              totalArea += c.area;
          })
        })
        break;

      case areaType.exclusiveAverageArea:
        {
          let count = 0;
          this.Buildings.forEach(b => {
            b.houses.forEach(h => {
              totalArea += h.exclusiveArea;
            })
            count += b.houses.length;
          })
          if (count !== 0)
            totalArea /= count;
        }
        break;

      default:
        totalArea = 0;
        break;
    }

    return totalArea.toFixed(2);
  }

  showLayerList = (type: LayerType) => {
    let layerList = true;
    let houseList = true;

    switch (type) {
      case LayerType.vacancyInside:
      case LayerType.vacancyOutsize:
      case LayerType.road:
      case LayerType.site:
      case LayerType.wall:
      case LayerType.window:
      case LayerType.core:
        layerList = false;
        break;

      case LayerType.house:
        houseList = false;
        break;

      case LayerType.none:
        break;

      default:
        break;
    }

    this.addType = type;

    this.setState({
      layerListHidden: layerList,
      houseListHidden: houseList,
    })
  }

  selectLayerForHome = (type: LayerType, building: BuildingType, house: House | Core) => {
    this.selectingLayer = {
      building: building,
      house: house,
      part: type
    };

    this.showLayerList(type);
  }

  switchAreaListHidden = () => {
    this.setState({
      areaListHidden: !this.state.areaListHidden,
    })
  }

  switchBuildingListHidden = () => {
    this.setState({
      buildingListHidden: !this.state.buildingListHidden,
    })
  }

  switchHouseListHidden = (building: BuildingType) => {
    building.showList = !building.showList;

    this.handled();
  }

  addBuildings = () => {
    this.Buildings.push({
      cores: [],
      houses: [],
      name: `building${this.Buildings.length + 1}`,
      showList: false,
    })

    this.handled();
  }

  addHouse = (building: BuildingType) => {
    let meshGroup = new THREE.Group();

    building.houses.push({
      wall: null,
      window: null,
      exclusiveArea: 59.98,
      balconyOver150cm: 0,
      balconyLess150cm: 0,
      serviceArea: 0,
      name: `house${building.houses.length + 1}`,
      outputPolygon: [],
      wall3DGroup: meshGroup,
      detialList: true,
      level: [],
      levelHeights: [],
      piloti: 0,
    });

    console.log(building);
    this.handled();
  }

  addCore = (building: BuildingType) => {
    let meshGroup = new THREE.Group();

    building.cores.push({
      core: null,
      houses: [],
      name: `core${building.cores.length + 1}`,
      area: 47.06,
      outputPolygon: [],
      wall3DGroup: meshGroup,
      level: [],
      levelHeights: [],
    })
    this.handled();
  }

  removeBuildingFromArray = (buildings: BuildingType[], building: BuildingType) => {
    building.cores = [];
    building.houses = [];
    let index = buildings.indexOf(building);
    if (index !== -1) {
      buildings.splice(index, 1);
    }
    this.handled();
  }

  removeHouseFromHouseList = (houses: House[], index: number) => {
    if (index > -1) {
      houses[index].wall3DGroup.children = [];
      houses.splice(index, 1);
    }

    this.handled();
  }

  removeLayerFromArray = (array: ConverterLayer[], layer: ConverterLayer) => {
    let i = array.indexOf(layer);
    if (i > -1) {
      this.switchLayerState(layer);
      array.splice(i, 1);
    }

    this.handled();
  }

  removeElementFromArray = (array: [], index: number) => {
    if (index > -1) {
      array.splice(index, 1);
    }

    this.handled();
  }

  valueChanged = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value))
      value = 0;

    switch (type) {
      case 'exclusive':
        (this.selectingLayer.house as House).exclusiveArea = value;
        break;
      case 'service':
        (this.selectingLayer.house as House).serviceArea = value;
        break;
      case 'less150':
        (this.selectingLayer.house as House).balconyLess150cm = value;
        (this.selectingLayer.house as House).serviceArea = (this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm;
        break;
      case 'over150':
        (this.selectingLayer.house as House).balconyOver150cm = value;
        (this.selectingLayer.house as House).serviceArea = (this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm;
        break;
      case 'core':
        (this.selectingLayer.house as Core).area = value;
        break;
      default:
        break;
    }

    this.handled();
  }

  levelChanged = (e: React.ChangeEvent<HTMLInputElement>, house: House) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value))
      value = 0;

    house.level = [];
    house.levelHeights = [];
    for (let i = 0; i < value; i++) {
      house.level.push(true);
      house.levelHeights.push(2.8);
    }
    this.handled();
  }

  pilotiChanged = (e: React.ChangeEvent<HTMLInputElement>, house: House) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value))
      value = 0;
    value = value > house.level.length ? house.level.length : value;

    for (let i = 0; i < house.level.length; i++) {
      if (i < value) {
        house.level[i] = false;
      }
      else {
        house.level[i] = true;
      }
    }

    house.piloti = value;

    this.handled();
  }

  detailButtonClicked = () => {
    (this.selectingLayer.house as House).detialList = !(this.selectingLayer.house as House).detialList;
    this.handled();
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  resizeCanvasResolution = () => {
    let proj = this.mapManager.getProjection();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = proj.fromPageXYToCoord(NaverPoint(0 + rect.left, 0 + rect.top));
    let p2 = proj.fromPageXYToCoord(NaverPoint(1 + rect.left, 0 + rect.top));
    let frustumSize = proj.getDistance(p1, p2) * this.state.screenWidth / 2;

    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);
  }

  sliderChaged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    this.sceneManager.setRendererAlpha(value);

    if (value === 1) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
      (this.refs.searchText as HTMLInputElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      (this.refs.searchText as HTMLInputElement).style.visibility = 'visible';
      this.resizeCanvasResolution();
    }

    this.setState({
      canvasAlpha: value,
    });
  }

  changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      address: e.target.value,
    });
  }

  calculateAreaProPortion = () => {
    let areaProportions: areaProportion[] = [];

    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        c.houses.forEach(h => {
          let ap = areaProportions.find(e => e.house_plan_type_area === h.exclusiveArea)
          if (!ap) {
            areaProportions.push({
              house_plan_type_area: h.exclusiveArea,
              housing_plan_type_number_min: (h.level.length - h.piloti),
              housing_plan_type_proportion: 0,
              number_of_bay: 0,
            })
          }
          else {
            ap.housing_plan_type_number_min += (h.level.length - h.piloti);
          }
        })
      })
    });

    let totalHousehold = this.getTotalHousehold();

    areaProportions.forEach(a => {
      a.housing_plan_type_proportion = Number((a.housing_plan_type_number_min / totalHousehold).toFixed(12));
    });

    return areaProportions;
  }

  getTotalHousehold = () => {
    let totalHousehold = 0;
    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        c.houses.forEach(h => {
          totalHousehold += (h.level.length - h.piloti);
        })
      })
    });
    return totalHousehold;
  }

  calculateResultLocation = () => {
    let floorAreaRatio = Number((Number(this.getAreas(areaType.totalGroundArea)) / Number(this.getAreas(areaType.vacancyInsideArea))).toFixed(12));

    let totalHousehold = this.getTotalHousehold();

    let result: resultLocation = {
      floor_area_ratio: floorAreaRatio,
      cover_area_ratio: Number((Number(this.getAreas(areaType.totalBuildingArea)) / Number(this.getAreas(areaType.vacancyInsideArea))).toFixed(12)),
      building_number: this.Buildings.length,
      construction_cost: Math.floor(Number(this.getAreas(areaType.vacancyInsideArea)) * floorAreaRatio * 0.01 * 1450167),
      total_household: totalHousehold,
    };

    return result;
  }

  buildingStoriesAvg = () => {
    let totalHouseLine = 0;
    let totalHouseHold = 0;
    let totalLevel = 0;
    let totalBaseArea = 0;

    this.Buildings.forEach(b => {
      let maxLevel = 0;
      let baseArea = 0;
      b.cores.forEach(c => {
        totalHouseLine += c.houses.length;
        c.houses.forEach(h => {
          totalHouseHold += (h.level.length - h.piloti);
          maxLevel = Math.max(maxLevel, h.level.length);
          baseArea += h.exclusiveArea;
        })
        baseArea += c.area;
      })
      totalLevel += maxLevel;
      totalBaseArea += baseArea / maxLevel;
    });

    let floorAvg_totalHouse = this.fixedNumber(totalHouseHold / totalHouseLine, 12);
    let floorAvg_Math = this.fixedNumber(totalLevel / this.Buildings.length, 12);
    let floorAvg_area = this.fixedNumber(Number(this.getAreas(areaType.totalBuildingArea)) / totalBaseArea, 12);

    return [floorAvg_area, floorAvg_Math, floorAvg_totalHouse];
  }

  fixedNumber = (n: number, length: number) => {
    return Number(n.toFixed(length));
  }

  captureImage = async(imageName: string) => {
    // 선택된것만 켜기
    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected)
          visible = true;
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible;
        })
      })
      resolve();
    })

    let captureBbox = new THREE.Box3();
    this.siteLayers.forEach(l => {
      l.polygons.forEach(p => {
        p.vertices.forEach(v => {
          captureBbox.expandByPoint(v);
        })
      })
    })

    let imgBuf = this.sceneManager.getScreenCapture(500, 500, captureBbox);
    this.onWindowResize();

    saveDataToS3(imgBuf, "teneleven-platform-my-building-plan", imageName, 'image/png');

    // 전체 켜기
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  saveToAWS = async () => {
    if (!this.naverMapProj || !this.basePosition) {
      alert('set base position!!!!!!!!!!');
      return;
    }

    let id = uuid4();

    let imageName = `my_building_plane_img_${id}.png`;
    await this.captureImage(imageName);

    let sceneOffset = this.mount!.getBoundingClientRect();
    let origin = this.sceneManager.getLatLonPosition(this.siteLayers[0].polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset);
    let mapOffset = new THREE.Vector2(this.basePosition.x, this.basePosition.y).sub(new THREE.Vector2(origin.x, origin.y));
    let buildingData: any[] = [];

    let buildingDataName = `my_building_plane_${id}.json`;
    this.Buildings.forEach(b => {
      buildingData.push(getBuildingTypeJson(b.cores));
    });

    let templates: any[] = [];
    let buildingIndex = 0;
    buildingData.forEach(b => {
      b.position = this.sceneManager.getPointToMap(b.position, sceneOffset, mapOffset, this.naverMapProj);
      b.templateList[0].template.name = `template${buildingIndex}`;
      templates.push(b.templateList[0].template);
      buildingIndex++;
    });
    // console.log(buildingData);
    saveDataToS3(JSON.stringify({ buildings: buildingData, templates: templates }), "teneleven-platform-my-building-plan", buildingDataName, 'application/json');

    let projectSite = this.sceneManager.getAreaWKTFile(this.siteLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let roadSite = this.sceneManager.getAreaWKTFile(this.roadLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let vacancyInside = this.sceneManager.getAreaWKTFile(this.vacancyInsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let vacancyOutside = this.sceneManager.getAreaWKTFile(this.vacancyOutsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let boundaryLine = getBoundaryLine(projectSite); 
    console.log(boundaryLine);

    let lMax = 0;
    let lMin = 10000;
    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        c.houses.forEach(h => {
          lMax = Math.max(h.level.length, lMax);
          lMin = Math.min(h.level.length, lMin);
        })
      })
    });

    let date = new Date().toISOString();
    let dbItem: buildingPlanStruct = {
      id: id,
      project_id_global: '1011',
      project_id_user: '0',
      report_id: '0',
      result_status: '1',
      area_proportion: this.calculateAreaProPortion(),
      building_stories_avg: this.buildingStoriesAvg(),
      building_stories_max: lMax,
      building_stories_min: lMin,
      result_location: this.calculateResultLocation(),
      name: 'test',
      user_id: 'test@1011.co.kr',
      address: await getAddressByProjectSite(projectSite),
      project_site_area: Number(this.getAreas(areaType.vacancyInsideArea)),
      project_site: projectSite,
      road_site_area: Number(this.getAreas(areaType.roadArea)),
      road_site: roadSite,
      vacancy_outside_area: Number(this.getAreas(areaType.vacancyOutsizeArea)),
      vacancy_outside: vacancyOutside,
      vacancy_inside_area: Number(this.getAreas(areaType.openSpaceArea)),
      vacancy_inside: vacancyInside,
      building_number: this.Buildings.length,
      total_building_area: Number(this.getAreas(areaType.totalBuildingArea)),
      total_floor_area: Number(this.getAreas(areaType.totalGroundArea)),
      average_exclusive_area: Number(this.getAreas(areaType.exclusiveAverageArea)),
      img_path: imageName,
      building_data_path: buildingDataName,
      create_at: date,
      modified_at: date,
      deleted: false
    };
    // console.log(dbItem);
    saveDataToDynamoDB(dbItem, 'platform-buildit-my-building-plan');
  }

  addSiteAreaToMap = () => {
    if (!this.mapManager.isCreate() || this.siteLayers.length === 0) {
      alert('select a site layer');
      return;
    }

    if (this.state.canvasAlpha === 1) {
      alert('show the map');
      return;
    }

    let sceneOffset = this.mount!.getBoundingClientRect();

    this.naverMapProj = this.mapManager.getProjection();
    this.basePosition = this.sceneManager.getLatLonPosition(this.siteLayers[0].polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset);
    let latlngs: any[] = [];
    this.siteLayers.forEach(l => {
      l.polygons.forEach(p => {
        let polygon: any[] = [];
        p.vertices.forEach(v => {
          polygon.push(this.sceneManager.getLatLonPosition(v.clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset));
        });
        latlngs.push(polygon);
      });
    });

    if (this.naverPolygon) {
      this.naverPolygon.setPaths(latlngs);
    }
    else {
      this.naverPolygon = NaverPolygon({
        map: this.mapManager.getMap(),
        paths: latlngs,
        fillColor: '#ff0000',
        fillOpacity: 0.3,
        strokeColor: '#ff0000',
        strokeOpacity: 0.6,
        strokeWeight: 3
      });
    }
  }

  render = () => {
    let layerDiv = <div />;
    let houseDiv = <div />;
    let houseAreaDiv = <div />;
    let coreAreaDiv = <div />;

    if (this.state.layers) {
      layerDiv = <div className='layers' hidden={this.state.layerListHidden}>
        <ul>{this.state.layers!.map(l =>
          <li key={l.name} onMouseOut={(e) => this.mouseOutLayerTag(e)} onMouseOver={(e) => this.mouseOverLayerTag(e, l)} onClick={() => this.layerClicked(l)}>{`${l.name}`}</li>)}
        </ul>
      </div>
    }

    if (this.selectingLayer.building) {
      houseDiv = <div className='houses' hidden={this.state.houseListHidden}>
        <ul>{this.selectingLayer.building.houses.map(h =>
          <li key={h.name} onMouseOut={(e) => this.mouseOutHouseTag(e)} onMouseOver={(e) => this.mouseOverHouseTag(e, h)} onClick={(e) => this.addHouse2Core(e, h)}>{h.name}</li>)}
        </ul>
      </div>
    }

    if (!this.state.layerListHidden && (this.selectingLayer.part === LayerType.wall)) {
      houseAreaDiv = <div className='extraMenu'>
        <div>전용 면적 <input type='number' value={(this.selectingLayer.house as House).exclusiveArea} onChange={(e) => this.valueChanged(e, 'exclusive')} min='0' ></input>㎡</div>
        <div>서비스 면적 <input type='number' value={!(this.selectingLayer.house as House).detialList ? ((this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm) : (this.selectingLayer.house as House).serviceArea} onChange={(e) => this.valueChanged(e, 'service')} min='0' disabled={!(this.selectingLayer.house as House).exclusiveArea} ></input>㎡
        <button onClick={this.detailButtonClicked}>{(this.selectingLayer.house as House).detialList ? '+' : '-'}</button></div>
        <div hidden={(this.selectingLayer.house as House).detialList}>1.5m 미만<input type='number' value={(this.selectingLayer.house as House).balconyLess150cm} onChange={(e) => this.valueChanged(e, 'less150')}></input></div>
        <div hidden={(this.selectingLayer.house as House).detialList}>1.5m 이상<input type='number' value={(this.selectingLayer.house as House).balconyOver150cm} onChange={(e) => this.valueChanged(e, 'over150')}></input></div>
      </div>
    }
    else {
      houseAreaDiv = <div></div>;
    }

    if (!this.state.layerListHidden && (this.selectingLayer.part === LayerType.core)) {
      coreAreaDiv = <div className='extraMenu'>
        <div>코어 면적 <input type='number' value={(this.selectingLayer.house as Core).area} onChange={(e) => this.valueChanged(e, 'core')} min='0' ></input>㎡</div>
      </div>
    }
    else {
      coreAreaDiv = <div />;
    }

    return (
      <React.Fragment>
        <header>CAD Converter</header>
        <div className='MainBody'>
          <div className='tablist'>
            <div>
              <input type='range' min='0' max='1' step='0.01' value={this.state.canvasAlpha} onChange={this.sliderChaged}></input>
            </div>
            <div>
              <input id='fileLable' type="file" onChange={this.loadDXFFile} accept=".dxf" />
            </div>
            <div><span onClick={this.switchAreaListHidden}>사업영역 설정</span></div>
            <div className='list' hidden={this.state.areaListHidden}>
              <ul>
                {this.siteLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeLayerFromArray(this.siteLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showLayerList(LayerType.site)}>사업영역 추가</button>
              <button onClick={() => this.addSiteAreaToMap()}>사업영역 맵 표시</button>

              <ul>
                {this.roadLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeLayerFromArray(this.roadLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showLayerList(LayerType.road)}>인접도로 추가</button>

              <ul>
                {this.vacancyOutsideLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeLayerFromArray(this.vacancyOutsideLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showLayerList(LayerType.vacancyOutsize)}>공지영역 추가</button>

              <ul>
                {this.vacancyInsideLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeLayerFromArray(this.vacancyInsideLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showLayerList(LayerType.vacancyInside)}>특수영역 추가</button>
            </div>

            <div><span onClick={this.switchBuildingListHidden}>배치안 설정</span></div>
            <div className='list' hidden={this.state.buildingListHidden}>
              <ul>
                {this.Buildings.map(b =>
                  <li key={b.name}>
                    <div>
                      <span className='buildingName' onClick={() => this.switchHouseListHidden(b)} >{b.name}</span> <span onClick={() => this.removeBuildingFromArray(this.Buildings, b)}>X</span>
                      <div hidden={b.showList}>
                        <button onClick={() => this.addHouse(b)}>House 추가</button>
                        <div className='list' >{b.houses.map(h =>
                          <div className='list' key={h.name}><div>{h.name}<span onClick={() => this.removeHouseFromHouseList(b.houses, b.houses.indexOf(h))}>X</span></div>
                            <ul>
                              <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayerForHome(LayerType.wall, b, h)}>{this.getPolygonLayerName(h.wall, LayerType.wall)}</li>
                              <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayerForHome(LayerType.window, b, h)}>{this.getPolygonLayerName(h.window, LayerType.window)}</li>
                              <li ><div className='levelDiv'>층수 : <input type='number' value={h.level.length} onChange={(e) => this.levelChanged(e, h)} min='1' ></input></div></li>
                              <li ><div className='levelDiv'>필로티 : <input type='number' value={h.piloti} onChange={(e) => this.pilotiChanged(e, h)} min='0' ></input></div></li>
                            </ul>
                          </div>)}
                        </div>
                        <button onClick={() => this.addCore(b)}>Core 추가</button>
                        <div className='list' >{b.cores.map(c =>
                          <div key={c.name}>{c.name}<span onClick={() => this.removeElementFromArray(b.cores as [], b.cores.indexOf(c))}>X</span>
                            <ul>
                              <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayerForHome(LayerType.core, b, c)}>{this.getPolygonLayerName(c.core, LayerType.core)}</li>
                              <li><button onClick={() => this.selectLayerForHome(LayerType.house, b, c)} disabled={!this.state.is2D}>add house</button></li>
                              {c.houses.map(h => <li key={h.name}><div>{h.name}<span onClick={() => this.removeElementFromArray(c.houses as [], c.houses.indexOf(h))}>X</span></div></li>)}
                            </ul>
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                <button onClick={() => this.addBuildings()}>동타입 추가</button>
              </ul>
            </div>
            <button onClick={() => this.saveToAWS()}>DB 추가</button>
          </div>
          <div className='RenderView'>
            <div className='information'>
              <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.getAreas(areaType.vacancyInsideArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>동타입 수</div><div className='inforValue'>{this.Buildings.length}</div></div>
              <div className='info'><div className='infoLabel'>건축면적</div><div className='inforValue'>{this.getAreas(areaType.totalBuildingArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>바닥면적</div><div className='inforValue'>{this.getAreas(areaType.totalGroundArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>평균 전용면적</div><div className='inforValue'>{this.getAreas(areaType.exclusiveAverageArea)}㎡</div></div>
            </div>
            <div className='Scene' ref={(mount) => { this.mount = mount }}>
              <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
              <input ref='searchText' className='mapSearch' type='text' value={this.state.address} onChange={(e) => this.changeAddress(e)} onKeyUp={(e) => this.textInputKeyUp(e)}></input>
              {layerDiv}
              {houseDiv}
              {houseAreaDiv}
              {coreAreaDiv}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
