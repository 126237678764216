import AWS from 'aws-sdk';
import * as THREE from 'three';
import { Core, houseStruct, LineInfo } from './DataTypes';

const ddb = new AWS.DynamoDB.DocumentClient({
  accessKeyId: "AKIAU2FCSQMKX2F56O6A",
  secretAccessKey: "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",
  region: "ap-northeast-2",
});

const s3 = new AWS.S3({
  accessKeyId: "AKIAU2FCSQMKX2F56O6A",
  secretAccessKey: "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",
  region: "ap-northeast-2",
});

const lamda = new AWS.Lambda({
  accessKeyId: "AKIAU2FCSQMKX2F56O6A",
  secretAccessKey: "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",
  region: "ap-northeast-2",
})

// const r = await ddb.get({
//   TableName: "platform-buildit-project",
//   Key: {
//     stage: "dev",
//     project_id: 15,
//   },
// }).promise();

// const r = await s3.getObject({
//   Bucket: "teneleven-pubdata-building-obj-apn2",
//   Key: "1121510100102460063.obj"
// }).promise();

export async function getAddressByProjectSite(projectSite: string[] | any) {
  const r = await lamda.invoke({
    FunctionName: 'platform-buildit-cad-converter-address',
    Payload: JSON.stringify(
      {
        project_site: projectSite,
      }
    ),
    Qualifier: "v20190726"
  }).promise();
  return JSON.parse(r.Payload as string)['address'];
}

export async function saveDataToS3(data: string | Buffer | Uint8Array | Blob | undefined, bucket: string, fileName: string, contentType: string) {
  await s3.putObject({
    Bucket: bucket,
    Key: fileName,
    Body: data,
    ContentType: contentType,
  }).promise();
}

export async function saveDataToDynamoDB(data: any, tableName: string) {
  await ddb.put({
    TableName: tableName,
    Item: data,
  }).promise();
}

export function checkDuplicatePointAndAdd(nodes: { x: number, y: number }[], node: { x: number, y: number }) {
  let exist = false;
  nodes.forEach(n => {
    if (new THREE.Vector2(n.x, n.y).distanceTo(new THREE.Vector2(node.x, node.y)) < 0.00001) {
      exist = true;
    }
  });

  if (!exist) {
    nodes.push({ x: Number(node.x.toFixed(8)), y: Number(node.y.toFixed(8)) });
  }
}

export function pushAllPoints(nodes: { x: number, y: number }[], polygon: LineInfo[], basePosition: THREE.Vector3) {
  for (let i = 0; i < polygon.length; i++) {
    let startPoint = polygon[i].line.start.clone().sub(basePosition);
    let endPoint = polygon[i].line.end.clone().sub(basePosition);
    if (i === 0) {
      checkDuplicatePointAndAdd(nodes, { x: startPoint.x, y: startPoint.y })
    }
    checkDuplicatePointAndAdd(nodes, { x: endPoint.x, y: endPoint.y })
  }
}

export function findVerticeIndex(nodes: { x: number, y: number }[], node: { x: number, y: number }) {
  let id = -1;
  for (let i = 0; i < nodes.length; i++) {
    if (new THREE.Vector2(nodes[i].x, nodes[i].y).distanceTo(new THREE.Vector2(node.x, node.y)) < 0.00001) {
      id = i;
    }
  }
  return id;
}

export function getBuildingTypeJson(cores: Core[]) {
  let template: any[] = [];
  let floorStatus: any[] = [];
  let group = 0;

  let bbox = new THREE.Box3();
  cores.forEach(c => {
    c.outputPolygon.forEach(p => {
      bbox.expandByPoint(p.line.start);
      bbox.expandByPoint(p.line.end);
    });

    c.houses.forEach(h => {
      h.outputPolygon.forEach(p => {
        bbox.expandByPoint(p.line.start);
        bbox.expandByPoint(p.line.end);
      })
    });
  })

  let minPos = bbox.min.clone();

  let rotateCenter = new THREE.Vector3();
  let rotateBox = new THREE.Box3();
  let nodes: { x: number, y: number }[] = [];
  cores.forEach(c => {
    pushAllPoints(nodes, c.outputPolygon, minPos);
    c.houses.forEach(h => {
      pushAllPoints(nodes, h.outputPolygon, minPos);
    })
  })

  cores.forEach(c => {
    let coreLines: any[] = [];
    for (let i = 0; i < c.outputPolygon.length; i++) {
      let startPoint = c.outputPolygon[i].line.start.clone().sub(minPos);
      let endPoint = c.outputPolygon[i].line.end.clone().sub(minPos);

      let startIndex = findVerticeIndex(nodes, { x: startPoint.x, y: startPoint.y });
      let endIndex = findVerticeIndex(nodes, { x: endPoint.x, y: endPoint.y });

      coreLines.push({
        linetype: c.outputPolygon[i].type,
        points: [endIndex, startIndex],
        thickness: c.outputPolygon[i].thickness,
      })
      rotateBox.expandByPoint(startPoint);
      rotateBox.expandByPoint(endPoint);
    }
    let coreOutline: houseStruct = {
      lines: coreLines,
      group: group,
      category: 'PCT_CORE',
      type: 'PCT_OUTLINE',
      areaInformation: { coreArea: c.area },
      housesInCore: c.houses.length,
    };
    floorStatus.push({name: c.name, category: 'PCT_CORE', level: c.level, levelHeights: c.levelHeights })
    template.push(coreOutline);

    c.houses.forEach(h => {
      let houseLines: any[] = [];
      for (let i = 0; i < h.outputPolygon.length; i++) {
        let startPoint = h.outputPolygon[i].line.start.clone().sub(minPos);
        let endPoint = h.outputPolygon[i].line.end.clone().sub(minPos);

        let startIndex = findVerticeIndex(nodes, { x: startPoint.x, y: startPoint.y });
        let endIndex = findVerticeIndex(nodes, { x: endPoint.x, y: endPoint.y });

        houseLines.push({
          linetype: h.outputPolygon[i].type,
          points: [endIndex, startIndex],
          thickness: h.outputPolygon[i].thickness
        });
      }
      let v: houseStruct = {
        lines: houseLines,
        group: group,
        category: 'PCT_HOUSE',
        type: 'PCT_OUTLINE',
        piloti: h.piloti,
        areaInformation: {
          exclusiveArea: h.exclusiveArea,
          serviceArea: h.serviceArea,
          balconyLess150cm: h.balconyLess150cm,
          balconyOver150cm: h.balconyOver150cm,
        },
        rooms: [],
      };
      floorStatus.push({name: h.name, category: 'PCT_HOUSE', level: h.level, levelHeights: h.levelHeights })
      template.push(v);
    })

    group++;
  });
  rotateBox.getCenter(rotateCenter);
  return {
    position: minPos,
    rotateAngle: 0,
    templateList: [{
      template: {
        houseline: template,
        name: `template`,
        rotateCenter: rotateCenter,
        groupTotal: cores.length,
        nodes: nodes,
        angleRange: [90, -90],
      },
      floorStatus: floorStatus
    }]
  };
}
