import React, { Component, Fragment } from 'react';
import './css/app.scss';
import { BrowserRouter as Router, Route} from "react-router-dom";
import * as BuildingTypeConverter from './BuildingTypeConverter';
import * as BuildingAreaConverter from './BuildingAreaConverter';
import * as ComplexConverter from './ComplexConverter';

interface State {
  mapLoaded: boolean;
}

class App extends Component<{}, State> {
  state = {
    mapLoaded: false,
  } as State;

  componentDidMount = async () => {
    while (true) {
      try {
        // @ts-ignore
        if (window.naver.maps.StyleMapTypeOption) {
          this.setState({
            mapLoaded: true,
          });

          break;
        } else {
          throw new Error("no naver map loadded");
        }
      } catch (e) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }
  }

  render () {
    if (this.state.mapLoaded) {
      return (
        <Router>
          <Fragment>
            <Route path="/cad/test1" render={() => <BuildingTypeConverter.Scene />} />
            <Route path="/cad/test2" render={() => <BuildingAreaConverter.Scene />} />
            <Route path="/cad/test3" render={() => <ComplexConverter.Scene />} />
          </Fragment>
        </Router>
      )
    } else {
      return (
        <div>Loading...</div>
      )
    }
  }
}

export default App;
