export class NaverMapManager {
  //@ts-ignore
  private naverMap = window.naver.maps;
  private map: any;

  createMap = (center: any, htmlElement: HTMLElement) => {
    if (this.map) {
      this.map.destroy();
    }

    this.map = new this.naverMap.Map(htmlElement, {
      mapTypes: this.naverMap.StyleMapTypeOption.getMapTypes({
        initLayers: [
          this.naverMap.StyleMapLayerId.BACKGROUND_DETAIL,
          this.naverMap.StyleMapLayerId.POI_KOREAN,
          this.naverMap.StyleMapLayerId.CADASTRAL,
        ]
      }),
      center: center,
      mapTypeId: this.naverMap.StyleMapTypeId.NORMAL,
      zoom: 15,
    });
  }

  getMap = () => {
    return this.map;
  }

  searchAddressToCoordinate = (address: string) => {
    this.naverMap.Service.geocode({
      query: address
    }, (status: any, response: any) => {
      if (status === this.naverMap.Service.Status.ERROR) {
        return alert('Something Wrong!');
      }

      if (response.v2.meta.totalCount === 0) {
        return alert('totalCount' + response.v2.meta.totalCount);
      }

      var htmlAddresses = [],
        item = response.v2.addresses[0],
        point = new this.naverMap.Point(item.x, item.y);

      if (item.roadAddress) {
        htmlAddresses.push('[도로명 주소] ' + item.roadAddress);
      }

      if (item.jibunAddress) {
        htmlAddresses.push('[지번 주소] ' + item.jibunAddress);
      }

      if (item.englishAddress) {
        htmlAddresses.push('[영문명 주소] ' + item.englishAddress);
      }

      this.map.setCenter(point);
    });

  }

  setMapCenter = (x: number, y: number) => {
    this.map.setCenter(new this.naverMap.Point(x, y));
  }

  addListener = (event: string, eventFunction: Function) => {
    this.naverMap.Event.addListener(this.map, event, () => {
      eventFunction();
    })
  }

  getProjection = () => {
    return this.map.getProjection();
  }

  isCreate = () => {
    if (this.map) {
      return true;
    }
    else {
      return false;
    }
  }

  getTMPosition = (screenPos:THREE.Vector2, proj: any) =>{
    return proj.fromPageXYToCoord(NaverPoint(screenPos.x, screenPos.y));
  }
}

export function NaverPoint(x: number, y: number) {
  //@ts-ignore
  return new window.naver.maps.Point(x, y);
}

export function NaverLatLng(lat: number, lng: number) {
  //@ts-ignore
  return new window.naver.maps.LatLng(lat, lng);
}

export function NaverPolygon(parameters: any) {
  //@ts-ignore
  return new window.naver.maps.Polygon(parameters);
}
