import * as THREE from '@teneleven/three';
import { Mesh, Group, Geometry } from '@teneleven/three';

import { LineType, LineInfo, wallMaterial, windowMaterial, coreMaterial, House, Core } from './DataTypes';
const earcut = require('earcut');

export function makeMeshWithLine(line: LineInfo): Mesh | Group {
  let lineStart = new THREE.Vector3(line.line.start.x, 0, line.line.start.y);
  let lineEnd = new THREE.Vector3(line.line.end.x, 0, line.line.end.y);

  let heightVector = new THREE.Vector3(0, 2.8, 0);
  let lineTopStart = lineStart.clone().add(heightVector);
  let lineTopEnd = lineEnd.clone().add(heightVector);

  if (line.type === LineType.LT_WINDOW) {
    // line.line.distance();
    let windowWidth = 2.4;
    if (line.line.distance() < 2.4) {
      windowWidth = line.line.distance();
    }

    let windowBottomStart = new THREE.Vector3(line.line.start.x, 2.6 - windowWidth, line.line.start.y);
    let windowTopStart = new THREE.Vector3(line.line.start.x, 2.6, line.line.start.y);

    let windowBottomEnd = new THREE.Vector3(line.line.end.x, 2.6 - windowWidth, line.line.end.y);
    let windowTopEnd = new THREE.Vector3(line.line.end.x, 2.6, line.line.end.y);

    let wallBottomGeo = new THREE.Geometry();
    wallBottomGeo.vertices = [lineStart, lineEnd, windowBottomStart, windowBottomEnd];
    wallBottomGeo.faces.push(new THREE.Face3(0, 1, 2));
    wallBottomGeo.faces.push(new THREE.Face3(1, 3, 2));
    wallBottomGeo.faces.push(new THREE.Face3(0, 2, 1));
    wallBottomGeo.faces.push(new THREE.Face3(1, 2, 3));
    wallBottomGeo.computeFaceNormals();

    let windowGeo = new THREE.Geometry();
    windowGeo.vertices = [windowBottomStart, windowBottomEnd, windowTopStart, windowTopEnd];
    windowGeo.faces.push(new THREE.Face3(0, 1, 2));
    windowGeo.faces.push(new THREE.Face3(1, 3, 2));
    windowGeo.faces.push(new THREE.Face3(0, 2, 1));
    windowGeo.faces.push(new THREE.Face3(1, 2, 3));

    windowGeo.computeFaceNormals();

    let wallTopGeo = new THREE.Geometry();
    wallTopGeo.vertices = [windowTopStart, windowTopEnd, lineTopStart, lineTopEnd];
    wallTopGeo.faces.push(new THREE.Face3(0, 1, 2));
    wallTopGeo.faces.push(new THREE.Face3(1, 3, 2));
    wallTopGeo.faces.push(new THREE.Face3(0, 2, 1));
    wallTopGeo.faces.push(new THREE.Face3(1, 2, 3));
    wallTopGeo.computeFaceNormals();

    let windowGroup = new THREE.Group();
    windowGroup.add(new THREE.Mesh(wallBottomGeo, wallMaterial));
    windowGroup.add(new THREE.Mesh(windowGeo, windowMaterial));
    windowGroup.add(new THREE.Mesh(wallTopGeo, wallMaterial));
    return windowGroup;
  }
  else {
    let geometry = new THREE.Geometry();
    geometry.vertices = [lineStart, lineEnd, lineTopStart, lineTopEnd];
    geometry.faces.push(new THREE.Face3(0, 1, 2));
    geometry.faces.push(new THREE.Face3(1, 3, 2));
    geometry.faces.push(new THREE.Face3(0, 2, 1));
    geometry.faces.push(new THREE.Face3(1, 2, 3));
    geometry.computeFaceNormals();

    if (line.type === LineType.LT_COREOUTERWALL)
      return new THREE.Mesh(geometry, coreMaterial);
    else
      return new THREE.Mesh(geometry, wallMaterial);
  }
}

export function remakeHousePolygons(house: House) {
  if (!house.wall || !house.window)
    return;

  let lines = new Array<LineInfo>();
  house.wall.polygons.forEach(p => {
    for (let i = 0; i < p.vertices.length - 1; i++) {
      lines.push({
        line: new THREE.Line3(p.vertices[i], p.vertices[i + 1]),
        thickness: 0.6,
        type: LineType.LT_OUTERWALL,
      })
    }
  })

  house.window.polygons.forEach(p => {
    let v1 = p.vertices[0];
    let v2 = p.vertices[1];
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].type === LineType.LT_OUTERWALL) {
        let p1 = new THREE.Vector3();
        let p2 = new THREE.Vector3();

        lines[i].line.closestPointToPoint(v1, false, p1);
        lines[i].line.closestPointToPoint(v2, false, p2);

        if ((v1.distanceTo(p1) < 0.01 && checkPointOnLine(p1, lines[i].line)) && (v2.distanceTo(p2) < 0.01 && checkPointOnLine(p2, lines[i].line))) {
          let l1: LineInfo, l2: LineInfo, l3: LineInfo;
          if (checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end))) {
            l1 = { line: new THREE.Line3(lines[i].line.start, p1), thickness: 0.6, type: LineType.LT_OUTERWALL };
            l2 = { line: new THREE.Line3(p1, p2), thickness: 0.6, type: LineType.LT_WINDOW };
            l3 = { line: new THREE.Line3(p2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
          }
          else {
            l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };
            l2 = { line: new THREE.Line3(p2, p1), thickness: 0.6, type: LineType.LT_WINDOW };
            l3 = { line: new THREE.Line3(p1, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
          }
          lines.splice(i, 1, l1, l2, l3);
        }
      }
    }
  });
  house.outputPolygon = lines;

  house.wall3DGroup.children = [];

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].line.distance() < 0.0001) {
      lines.splice(i, 1);
      i = 0;
    }
  }

  let ss = 0;
  for (let i = 0; i < lines.length - 1; i++) {
    let v1 = lines[i].line.start.clone().sub(lines[i].line.end);
    let v2 = lines[i + 1].line.start.clone().sub(lines[i + 1].line.end);
    ss += v1.dot(v2);
  }
  console.log(ss);
  // let v1 = lines[0].line.start.clone().sub(lines[0].line.end);
  // let v2 = lines[1].line.start.clone().sub(lines[1].line.end);
  // let v3 = v1.clone().dot(v2);
  // console.log(v1, v2, v3);
  lines.forEach(l => {
    if (ss > 0) {
      let s = l.line.start;
      l.line.start = l.line.end;
      l.line.end = s;
    }

    house.wall3DGroup.add(makeMeshWithLine(l));
  });

  house.wall.polygons.forEach(p => {
    let verts: number[] = [];
    // let roofGeo = new Geometry();
    let groundGeo = new Geometry();
    p.vertices.forEach(v => {
      // roofGeo.vertices.push(new THREE.Vector3(v.x, 2.8, v.y));
      groundGeo.vertices.push(new THREE.Vector3(v.x, 0, v.y));
      verts.push(v.x);
      verts.push(v.y);
    });
    let triangles = earcut(verts);

    for (let i = 0; i < triangles.length; i += 3) {
      // roofGeo.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));
      groundGeo.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));
      groundGeo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));
    }

    // house.wall3DGroup.add(new THREE.Mesh(roofGeo, new THREE.MeshLambertMaterial()));
    house.wall3DGroup.add(new THREE.Mesh(groundGeo, new THREE.MeshLambertMaterial()));
  });
}

export function checkPointOnLine(point: THREE.Vector3, line: THREE.Line3) {
  // console.log(line.distance(), point.distanceTo(line.start), point.distanceTo(line.end));
  if (((point.distanceTo(line.start) + point.distanceTo(line.end)) <= line.distance() + 0.001))
    return true;
  else
    return false;
}

export function remakeCorePolygons(core: Core) {
  if (!core.core)
    return;

  let lines = new Array<LineInfo>();
  core.wall3DGroup.children = [];

  core.core.polygons.forEach(p => {
    for (let i = 0; i < p.vertices.length - 1; i++) {
      lines.push({
        line: new THREE.Line3(p.vertices[i], p.vertices[i + 1]),
        thickness: 0.6,
        type: LineType.LT_COREOUTERWALL,
      })
    }

    let verts: number[] = [];
    // let roofGeo = new Geometry();
    let groundGeo = new Geometry();
    p.vertices.forEach(v => {
      // roofGeo.vertices.push(new THREE.Vector3(v.x, 2.8, v.y));
      groundGeo.vertices.push(new THREE.Vector3(v.x, 0, v.y));
      verts.push(v.x);
      verts.push(v.y);
    });
    let triangles = earcut(verts);

    for (let i = 0; i < triangles.length; i += 3) {
      // roofGeo.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));
      groundGeo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));
      groundGeo.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));
    }

    // core.wall3DGroup.add(new THREE.Mesh(roofGeo, new THREE.MeshLambertMaterial({ color: new THREE.Color(0x270a04) })));
    core.wall3DGroup.add(new THREE.Mesh(groundGeo, new THREE.MeshLambertMaterial({ color: new THREE.Color(0x270a04) })));
    // core.area += p.area;
    // core.area = 0;
  })

  core.outputPolygon = lines;
  lines.forEach(l => {
    let s = l.line.start;
    l.line.start = l.line.end;
    l.line.end = s;

    core.wall3DGroup.add(makeMeshWithLine(l));
  })
}


