import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import './css/BuildingTypeConverter.scss';
import '@teneleven/protocols-ts-web';

import { House, PartOfHouse, ConverterLayer, areaType, siteStruct } from './DataTypes';
import { dataParsing, asyncFileRead, switchLineDashedState } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng, NaverPolygon } from './NaverMapManager';
import { SceneManager } from './SceneManager';

const uuid4 = require('uuid/v4');

export interface SceneProps {

}

export interface SceneState {
  layers: ConverterLayer[];
  house: House[];
  handle: number;
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
}

enum LayerType {
  site,
  road,
  vacancyOutsize,
  vacancyInside,
  none
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    house: [],
    handle: 0,
    is2D: true,
    screenWidth: window.innerWidth * 0.9,
    screenHeight: window.innerHeight - 70,
    address: '마포구 월드컵북로 396',
    canvasAlpha: 1,
  };

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  siteLayers = new Array<ConverterLayer>();
  roadLayers = new Array<ConverterLayer>();
  vacancyOutsideLayers = new Array<ConverterLayer>();
  vacancyInsideLayers = new Array<ConverterLayer>();

  naverPolygon: any;
  naverMapProj: any;
  basePosition: any;

  layerListHidden = true;
  addType = LayerType.site;

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.sceneManager.SceneInit();

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.resizeCanvasResolution);


    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    window.addEventListener("keydown", this.onKeyDown, false);
  }

  componentDidUpdate = (previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      this.changeRendererSize();
    }
  }

  changeRendererSize = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;

    let frustumSize = 0;
    if (this.state.canvasAlpha === 1) {
      frustumSize = this.sceneManager.orthoCamera.right;
    } else {
      let proj = this.mapManager.getProjection();
      let rect = this.mount!.getBoundingClientRect();
      let p1 = proj.fromPageXYToCoord(NaverPoint(0 + rect.left, 0 + rect.top));
      let p2 = proj.fromPageXYToCoord(NaverPoint(1 + rect.left, 0 + rect.top));
      frustumSize = proj.getDistance(p1, p2) * this.state.screenWidth / 2;
    }

    this.sceneManager.CameraFrustumResize(frustumSize, aspect);
    this.sceneManager.renderer.setSize(this.state.screenWidth, this.state.screenHeight);
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth * 0.9,
      screenHeight: window.innerHeight - 70,
    });
  }

  exportWktFile = async () => {
    if (!this.naverMapProj || !this.basePosition) {
      alert('set base position!!!!!!!!!!');
      return;
    }

    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible;
        })
      })
      resolve();
    })

    let captureBbox = new THREE.Box3();
    this.siteLayers.forEach(l => {
      l.polygons.forEach(p => {
        p.vertices.forEach(v => {
          captureBbox.expandByPoint(v);
        })
      })
    })

    let imgBuf = this.sceneManager.getScreenCapture(500, 500, captureBbox);
    this.changeRendererSize();

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    let id = uuid4();
    let img_path = `site_image_${id}.png`;
    let date = new Date().toISOString();
    saveDataToS3(imgBuf, "teneleven-platform-my-site", img_path, 'image/png');

    let sceneOffset = this.mount!.getBoundingClientRect();
    let origin = this.sceneManager.getLatLonPosition(this.siteLayers[0].polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset);
    let mapOffset = new THREE.Vector2(this.basePosition.x, this.basePosition.y).sub(new THREE.Vector2(origin.x, origin.y));

    let projectSite = this.sceneManager.getAreaWKTFile(this.siteLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let roadSite = this.sceneManager.getAreaWKTFile(this.roadLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let vacancyInside = this.sceneManager.getAreaWKTFile(this.vacancyInsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);
    let vacancyOutside = this.sceneManager.getAreaWKTFile(this.vacancyOutsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix);

    let dbItem: siteStruct = {
      id: id,
      name: 'test',
      user_id: 'test@1011.co.kr',
      address: await getAddressByProjectSite(projectSite),
      project_site_area: Number(this.getAreas(areaType.vacancyInsideArea)),
      project_site: projectSite,
      road_site_area: Number(this.getAreas(areaType.roadArea)),
      road_site: roadSite,// this.sceneManager.getAreaWKTFile(this.roadLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix),
      vacancy_outside_area: Number(this.getAreas(areaType.vacancyOutsizeArea)),
      vacancy_outside: vacancyOutside,// this.sceneManager.getAreaWKTFile(this.vacancyOutsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix),
      vacancy_inside_area: Number(this.getAreas(areaType.openSpaceArea)),
      vacancy_inside: vacancyInside,// this.sceneManager.getAreaWKTFile(this.vacancyInsideLayers, sceneOffset, mapOffset, this.naverMapProj, this.polygon2DGroup.matrix),
      img_path: img_path,
      create_at: date,
      modified_at: date,
      deleted: false,
    };
    saveDataToDynamoDB(dbItem, 'platform-buildit-my-site');
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      default:
        break;
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      default:
        break;
    }
  }

  dataInitialize = () => {
    this.siteLayers = [];
    this.roadLayers = [];
    this.vacancyOutsideLayers = [];
    this.vacancyInsideLayers = [];

    // this.orthoCamera = new THREE.OrthographicCamera(-50, 50, 50, -50, 0.001, 10000);
    this.bbox = new THREE.Box3();
    if (this.naverPolygon)
      this.naverPolygon.setPaths([]);
    this.setState({
      layers: [],
    })
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let data = await asyncFileRead(e.target.files!);
    if (!data)
      return;

    this.dataInitialize();
    let layer = dataParsing(data);

    this.polygon2DGroup.children = [];

    this.bbox.makeEmpty();
    layer.forEach(l => {
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
    });

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(0, 0, 1);
    this.sceneManager.orthoControl.target.set(0, 0, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      layers: layer,
    })
  }

  handled = () => {
    this.setState({
      handle: this.state.handle + 1,
    })
  }

  switchLayerState(l: ConverterLayer) {
    if (l.selected) {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, true);
      });
      l.selected = false;
    }
    else {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, false);
      })
      l.selected = true;
    }
  }


  layerClicked = (layer: ConverterLayer) => {
    if (layer.selected) {
      alert('selected layer!!!!!');
      return;
    }

    switch (this.addType) {
      case LayerType.site:
        this.siteLayers.push(layer);
        break;
      case LayerType.road:
        this.roadLayers.push(layer);
        break;
      case LayerType.vacancyOutsize:
        this.vacancyOutsideLayers.push(layer);
        break;
      case LayerType.vacancyInside:
        this.vacancyInsideLayers.push(layer);
        break;
      default:
        break;
    }

    this.switchLayerState(layer);
    this.showList(LayerType.none);

    this.handled();
  }

  mouseOverLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, layer: ConverterLayer) => {
    e.currentTarget.style.background = this.mouseOverLayerColor;
    this.state.layers.forEach(l => {
      let visible = false;
      if (l.name === layer.name)
        visible = true;
      l.polygons.forEach(p => {
        p.lineMesh.visible = visible;
      })
    })
  }

  mouseOutLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.currentTarget.style.background = this.baseLayerColor;
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  removeElementFromArray = (array: ConverterLayer[], layer: ConverterLayer) => {
    // 
    let i = array.indexOf(layer);
    if (i > -1) {
      this.switchLayerState(layer);
      array.splice(i, 1);
    }

    this.handled();
  }

  getPolygonLayerName = (layer: ConverterLayer | null, part: PartOfHouse) => {
    if (layer)
      return layer.name;
    else
      return 'Select a ' + part;
  }

  getAreas = (area: areaType) => {
    let totalArea = 0;

    switch (area) {
      case areaType.vacancyInsideArea:
        this.siteLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        return totalArea.toFixed(2);

      case areaType.roadArea:
        this.roadLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        return totalArea.toFixed(2);

      case areaType.vacancyOutsizeArea:
        this.vacancyOutsideLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        return totalArea.toFixed(2);

      case areaType.openSpaceArea:
        this.vacancyInsideLayers.forEach(l => {
          l.polygons.forEach(p => {
            totalArea += p.area;
          })
        });
        return totalArea.toFixed(2);

      default:
        return '0';
    }
  }

  changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      address: e.target.value,
    });
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.Math.degToRad(degree));
  }

  addSiteAreaToMap = () => {
    if (!this.mapManager.isCreate() || this.siteLayers.length === 0)
      return;

    let sceneOffset = this.mount!.getBoundingClientRect();

    this.naverMapProj = this.mapManager.getProjection();
    this.basePosition = this.sceneManager.getLatLonPosition(this.siteLayers[0].polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset);
    let latlngs: any[] = [];
    this.siteLayers.forEach(l => {
      l.polygons.forEach(p => {
        let polygon: any[] = [];
        p.vertices.forEach(v => {
          polygon.push(this.sceneManager.getLatLonPosition(v.clone().applyMatrix4(this.polygon2DGroup.matrix), this.naverMapProj, sceneOffset));
        });
        latlngs.push(polygon);
      });
    });

    if (this.naverPolygon) {
      this.naverPolygon.setPaths(latlngs);
    }
    else {
      this.naverPolygon = NaverPolygon({
        map: this.mapManager.getMap(),
        paths: latlngs,
        fillColor: '#ff0000',
        fillOpacity: 0.3,
        strokeColor: '#ff0000',
        strokeOpacity: 0.6,
        strokeWeight: 3
      });
    }
  }

  showList = (type: LayerType) => {
    switch (type) {
      case LayerType.site:
      case LayerType.road:
      case LayerType.vacancyOutsize:
      case LayerType.vacancyInside:
        if (!this.layerListHidden && type === this.addType) {
          this.layerListHidden = true;
        }
        else {
          this.layerListHidden = false;
        }
        break;

      case LayerType.none:
        this.layerListHidden = true;
        break;

      default:
        break;
    }
    this.addType = type;

    this.handled();
  }

  resizeCanvasResolution = () => {
    let proj = this.mapManager.getProjection();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = proj.fromPageXYToCoord(NaverPoint(0 + rect.left, 0 + rect.top));
    let p2 = proj.fromPageXYToCoord(NaverPoint(1 + rect.left, 0 + rect.top));
    let frustumSize = proj.getDistance(p1, p2) * this.state.screenWidth / 2;

    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);
  }

  sliderChaged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    this.sceneManager.setRendererAlpha(value);

    if (value === 1) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
      (this.refs.searchText as HTMLInputElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      (this.refs.searchText as HTMLInputElement).style.visibility = 'visible';
      this.resizeCanvasResolution();
    }

    this.setState({
      canvasAlpha: value,
    });
  }

  render = () => {
    let layerDiv = <div />;

    if (this.state.layers) {
      layerDiv = <div className='layers' hidden={this.layerListHidden}>
        <ul>{this.state.layers!.map(l =>
          <li key={l.name} onMouseOut={(e) => this.mouseOutLayerTag(e)} onMouseOver={(e) => this.mouseOverLayerTag(e, l)} onClick={() => this.layerClicked(l)}>{`${l.name}`}</li>)}
        </ul>
      </div>
    }

    return (
      <React.Fragment>
        <header>CAD Converter</header>
        <div className='MainBody'>
          <div className='tablist'>
            <div>
              <input id='fileLable' type="file" onChange={this.loadDXFFile} accept=".dxf" />
            </div>
            <div className='list'>
              <ul>
                {this.siteLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeElementFromArray(this.siteLayers, l)}>X</span></li>)}
                <button onClick={() => this.addSiteAreaToMap()}>사업영역 맵 표시</button>
              </ul>
              <button onClick={() => this.showList(LayerType.site)}>사업영역 추가</button>

              <ul>
                {this.roadLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeElementFromArray(this.roadLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showList(LayerType.road)}>인접도로 추가</button>

              <ul>
                {this.vacancyOutsideLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeElementFromArray(this.vacancyOutsideLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showList(LayerType.vacancyOutsize)}>공지영역 추가</button>

              <ul>
                {this.vacancyInsideLayers.map(l => <li key={l.name}>{l.name}<span onClick={() => this.removeElementFromArray(this.vacancyInsideLayers, l)}>X</span></li>)}
              </ul>
              <button onClick={() => this.showList(LayerType.vacancyInside)}>특수영역 추가</button>
              <button onClick={() => this.exportWktFile()}>DB 추가</button>
              <div>
                <input type='range' min='0' max='1' step='0.01' value={this.state.canvasAlpha} onChange={this.sliderChaged}></input>
              </div>
              <div>
                <button style={{ width: '50%' }} onClick={() => this.objectRotate(1)}>left</button>
                <button style={{ width: '50%' }} onClick={() => this.objectRotate(-1)}>right</button>
              </div>
            </div>
          </div>

          <div className='RenderView'>
            <div className='information'>
              <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.getAreas(areaType.vacancyInsideArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>인접도로 면적</div><div className='inforValue'>{this.getAreas(areaType.roadArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>공지영역 면적</div><div className='inforValue'>{this.getAreas(areaType.vacancyOutsizeArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>특수영역 면적</div><div className='inforValue'>{this.getAreas(areaType.openSpaceArea)}㎡</div></div>
            </div>
            <div className='Scene' ref={(mount) => { this.mount = mount }}>
              <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
              <input ref='searchText' className='mapSearch' type='text' value={this.state.address} onChange={(e) => this.changeAddress(e)} onKeyUp={(e) => this.textInputKeyUp(e)}></input>
              {layerDiv}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
