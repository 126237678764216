import * as THREE from '@teneleven/three';

export enum entityType {
  LWPOLYLINE = 'LWPOLYLINE',
  LINE = 'LINE',
  POLYLINE = 'POLYLINE',
}

export enum PartOfHouse {
  wall = 'wall',
  window = 'window',
  core = 'core',
  house = 'house',
}

export enum areaType {
  exclusiveArea,
  serviceArea,
  coreArea,
  buildingArea,
  groundArea,
  roadArea,
  vacancyOutsizeArea,
  vacancyInsideArea,
  openSpaceArea,
  totalBuildingArea,
  totalGroundArea,
  exclusiveAverageArea,
}

export interface House {
  name: string;
  wall: ConverterLayer | null;
  window: ConverterLayer | null;
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  outputPolygon: LineInfo[];
  wall3DGroup: THREE.Group;
  detialList: boolean;
  level: boolean[];
  levelHeights: number[];
  piloti: number;
}

export interface Core {
  core: ConverterLayer | null;
  area: number;
  name: string;
  houses: House[];
  level: boolean[];
  levelHeights: number[];
  outputPolygon: LineInfo[];
  wall3DGroup: THREE.Group;
}

export interface ConverterLayer {
  name: string;
  visible: boolean;
  colorIndex: number;
  color: number;
  frozen: boolean;
  polygons: Polygon[];
  selected: boolean;
}

export interface LineInfo {
  line: THREE.Line3;
  type: LineType;
  thickness: number;
}

export enum LineType {
  LT_EMPTY = 'LT_EMPTY',
  LT_WALL = 'LT_WALL',
  LT_OUTERWALL = 'LT_OUTERWALL',     //외벽
  LT_INNERWALL = 'LT_INNERWALL',     //내벽
  LT_COREOUTERWALL = 'LT_COREOUTERWALL',     //코어외벽
  LT_COREINNERWALL = 'LT_COREINNERWALL',     //코어내벽
  LT_SIDEWALL = 'LT_SIDEWALL',     //측벽

  LT_WINDOW = 'LT_WINDOW',   //내창(방)
  LT_INNERWINDOW = 'LT_INNERWINDOW',   //내창(방)
  LT_OUTERWINDOW = 'LT_OUTERWINDOW',   //외창
  LT_LIGHTWINDOW = 'LT_LIGHTWINDOW',   //채광창
  LT_VIEWWINDOW = 'LT_VIEWWINDOW',   //조망창
  LT_LVWINDOW = 'LT_LVWINDOW',   //조망+채광창

  LT_DOOR = 'LT_DOOR',  //방문
  LT_SLIDE = 'LT_SLIDE',  //슬라이드
  LT_ENTRANCE = 'LT_ENTRANCE',  //현관문
  LT_GATE = 'LT_GATE',  //건물출입문
}

export enum PolygonType {
  PT_OUTLINE = 'PT_OUTLINE',

  PT_ENTRANCE = 'PT_ENTRANCE', //현관
  PT_UTILITY = 'PT_UTILITY', //다용도실
  PT_DRESS = 'PT_DRESS', //드레스룸
  PT_REST = 'PT_REST', //화장실
  PT_BALCONY = 'PT_BALCONY', //발코니
  PT_BED = 'PT_BED', //침실
  PT_DINING = 'PT_DINING', //주방
  PT_KITCHEN = 'PT_KITCHEN', //부엌
  PT_LIVING = 'PT_LIVING', //거실
  PT_STRUCT = 'PT_STRUCT',//설비실

  PT_ENTEXCL = 'PT_ENTEXCL',//현관앞공간

  PT_CORE = 'PT_CORE',//코어
  PT_ELEVATOR = 'PT_ELEVATOR',//엘리베이터
  PT_STAIRWAY = 'PT_STAIRWAY',//계단실

  PT_EMPTY = 'PT_EMPTY',//임시
}

export interface Polygon {
  lineMesh: any;
  plane?: THREE.Mesh;
  vertices: THREE.Vector3[];
  area: number;
  type: entityType;
  motherPolygon?: Polygon;
}

export interface buildingTypeStruct {
  id: string,
  user_id: string,
  name: string
  total_exclusive_area: number,
  total_service_area: number,
  core_area: number,
  building_area: number,
  floor_area: number,
  houses_number: number,
  img_path: string,
  file_path: string,
  created_at: string,
  modified_at: string,
  deleted: boolean
}

export interface buildingPlanStruct {
  id: string,
  project_id_global: string,
  project_id_user:string,
  report_id:string,
  result_status: string,
  result_location: resultLocation,
  building_stories_avg: number[],
  building_stories_max: number,
  building_stories_min: number,
  area_proportion: areaProportion[],
  name: string,
  user_id: string,
  building_number: number,
  vacancy_inside: string | any[],//특수영역
  vacancy_inside_area: number,
  vacancy_outside: string | any[],//공지영역
  vacancy_outside_area: number,
  project_site: string | any[],
  project_site_area: number,
  road_site: string | any[],
  road_site_area: number,
  total_floor_area: number,
  total_building_area: number,
  average_exclusive_area: number,
  building_data_path: string,
  img_path: string,
  create_at: string,
  modified_at: string,
  // floor_area_ratio: number, //용적률
  address: string,
  deleted: boolean
}

export interface resultLocation {
  total_household: number,
  cover_area_ratio: number,
  floor_area_ratio: number,
  building_number: number,
  construction_cost: number,
}

export interface areaProportion{
  house_plan_type_area: number,
  number_of_bay: number,
  housing_plan_type_proportion: number,
  housing_plan_type_number_min: number,
}

export interface siteStruct {
  id: string,
  name: string,
  user_id: string,
  vacancy_inside: string | any[], //특수영역
  vacancy_inside_area: number,
  vacancy_outside: string | any[], //공지영역
  vacancy_outside_area: number,
  project_site: string | any[],
  project_site_area: number,
  road_site: string | any[],
  road_site_area: number,
  img_path: string,
  create_at: string,
  modified_at: string,
  address: string,
  deleted: boolean
}

export interface lineStruct{
  linetype: LineType,
  points: [THREE.Vector3, THREE.Vector3],
  thickness: number,
}

export interface houseStruct{
  lines: lineStruct[];
  group: number,
  category: string,
  type: string,
  housesInCore?:number,
  // level: boolean[],
  // levelHeights: number[];
  piloti?: number,
  areaInformation: houseAreaStruct,
  rooms?: any[],
}

export interface houseAreaStruct{
  exclusiveArea?: number,
  coreArea?: number,
  serviceArea?: number,
  balconyLess150cm?: number,
  balconyOver150cm?: number,
}

let wallMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0xffffff) });
let coreMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0x270a04) });
let windowMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0x00ffff) });

export { wallMaterial, coreMaterial, windowMaterial };

