import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import './css/BuildingTypeConverter.scss';
import '@teneleven/protocols-ts-web';

import { House, Core, PartOfHouse, ConverterLayer, areaType, buildingTypeStruct } from './DataTypes';
import * as MeshMaker from './MeshMaker';
import { dataParsing, asyncFileRead, switchLineDashedState } from './FileParser';
import { saveDataToS3, getBuildingTypeJson, saveDataToDynamoDB } from './DBManager';
import { SceneManager } from './SceneManager';

const uuid4 = require('uuid/v4');

interface selectHouse {
  house: House | Core;
  part: PartOfHouse;
}

export interface SceneProps {

}

export interface SceneState {
  layers: ConverterLayer[];
  house: House[];
  handle: number;
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    house: [],
    handle: 0,
    is2D: true,
    screenWidth: window.innerWidth * 0.9,
    screenHeight: window.innerHeight - 70,
  };

  mount: HTMLDivElement | null = null;

  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  houses = Array<House>();
  cores = Array<Core>();

  selectingLayer = {} as selectHouse;

  layerListHidden = true;
  houseListHidden = true;
  housesListHidden = false;
  coreListHidden = false;

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();

    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);

    this.sceneManager.addObjectToScene(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.sceneManager.addObjectToScene(light);
    this.sceneManager.addObjectToScene(light.target);

    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate = (previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let aspect = this.state.screenWidth / this.state.screenHeight;
      let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(this.state.screenWidth, this.state.screenHeight);
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // console.log(Math.min(0, Infinity));
        break;
      default:
        break;
    }
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let data = await asyncFileRead(e.target.files!);
    let layer = dataParsing(data);

    this.polygon2DGroup.children = [];
    this.polygon3DGroup.children = [];

    this.bbox.makeEmpty();
    layer.forEach(l => {
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
    });

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(0, 0, 1);
    this.sceneManager.orthoControl.target.set(0, 0, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      layers: layer,
    })
  }

  addHouse = () => {
    let meshGroup = new THREE.Group();
    meshGroup.visible = false;
    this.polygon3DGroup.add(meshGroup);

    this.houses.push({
      wall: null,
      window: null,
      exclusiveArea: 85,
      balconyOver150cm: 0,
      balconyLess150cm: 15,
      serviceArea: 15,
      name: `house${this.houses.length + 1}`,
      outputPolygon: [],
      wall3DGroup: meshGroup,
      detialList: true,
      level: [true],
      levelHeights: [2.8],
      piloti: 0,
    })

    this.setState({
      house: this.houses,
      handle: this.state.handle + 1,
    })
  }

  addCore = () => {
    let meshGroup = new THREE.Group();
    meshGroup.visible = false;
    this.polygon3DGroup.add(meshGroup);
    this.cores.push({
      core: null,
      houses: [],
      name: `core${this.cores.length + 1}`,
      area: 0,
      outputPolygon: [],
      wall3DGroup: meshGroup,
      level: [true],
      levelHeights: [2.8],
    })
    this.handled();
  }

  handled = () => {
    this.setState({
      handle: this.state.handle + 1,
    })
  }

  switchLayerState(l: ConverterLayer) {
    if (l.selected) {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, true);
      });
      l.selected = false;
    }
    else {
      l.polygons.forEach(p => {
        switchLineDashedState(p.lineMesh.material, false);
      })
      l.selected = true;
    }
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth * 0.9,
      screenHeight: window.innerHeight - 70,
    });
  }

  layerClicked = (layer: ConverterLayer) => {
    if (layer.selected) {
      alert('selected layer!!!!!');
      return;
    }

    let exLayer;

    switch (this.selectingLayer.part) {
      case PartOfHouse.wall:
        exLayer = (this.selectingLayer.house as House).wall;
        (this.selectingLayer.house as House).wall = layer;
        MeshMaker.remakeHousePolygons((this.selectingLayer.house as House));
        break;

      case PartOfHouse.window:
        exLayer = (this.selectingLayer.house as House).window;
        (this.selectingLayer.house as House).window = layer;
        MeshMaker.remakeHousePolygons((this.selectingLayer.house as House));
        break;

      case PartOfHouse.core:
        exLayer = (this.selectingLayer.house as Core).core;
        (this.selectingLayer.house as Core).core = layer;
        MeshMaker.remakeCorePolygons((this.selectingLayer.house as Core));
        break;

      default:
        console.log('nothing');
        break;
    }

    console.log(this.houses);
    if (exLayer)
      this.switchLayerState(exLayer);

    this.switchLayerState(layer);
    this.showList('none');
    this.handled();
  }

  mouseOverLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, layer: ConverterLayer) => {
    e.currentTarget.style.background = this.mouseOverLayerColor;
    this.state.layers.forEach(l => {
      let visible = false;
      if (l.name === layer.name)
        visible = true;
      l.polygons.forEach(p => {
        p.lineMesh.visible = visible;
      })
    })
  }

  mouseOutLayerTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.currentTarget.style.background = this.baseLayerColor;
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  mouseOverHouseTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, house: House) => {
    e.currentTarget.style.background = this.mouseOverLayerColor;
    this.state.layers.forEach(l => {
      let visible = false;
      if ((house.wall && l.name === house.wall.name) || (house.window && l.name === house.window.name))
        visible = true;
      l.polygons.forEach(p => {
        p.lineMesh.visible = visible;
      })
    })
  }

  mouseOutHouseTag = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.currentTarget.style.background = this.baseLayerColor;
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  showList = (type: string) => {
    switch (type) {
      case 'house':
        this.layerListHidden = true;
        this.houseListHidden = false;
        break;

      case 'layer':
        this.layerListHidden = false;
        this.houseListHidden = true;
        break;

      case 'none':
        this.layerListHidden = true;
        this.houseListHidden = true;
        break;

      default:
        break;
    }
  }

  selectLayer = (house: House | Core, part: PartOfHouse) => {
    this.selectingLayer = { house, part };
    if (part === PartOfHouse.house) {
      this.showList('house');
    }
    else {
      this.showList('layer');
    }

    this.handled();
  }

  addHouse2Core = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, house: House) => {
    let h = (this.selectingLayer.house as Core).houses.find(element => element.name === house.name);
    if (h)
      alert('selectd house!!!!!');
    else
      (this.selectingLayer.house as Core).houses.push(house);

    this.showList('none');

    this.handled();
  }

  removeElementFromArray = (array: [], i: number) => {
    if (i > -1) {
      array.splice(i, 1);
    }

    this.handled();
  }

  removeHouseFromHouseList = (houses: House[], i: number) => {
    if (i > -1) {
      houses[i].wall3DGroup.children = [];
      houses.splice(i, 1);
    }

    this.handled();
  }

  getPolygonLayerName = (layer: ConverterLayer | null, part: PartOfHouse) => {
    if (layer)
      return layer.name;
    else
      return 'Select a ' + part;
  }

  getAreas = (area: areaType) => {
    let totalExclusiveAreas = 0;
    let totalServiceAreas = 0;
    let coreAreas = 0;

    this.cores.forEach(c => {
      if (c.core)
        c.core.polygons.forEach(p => coreAreas += p.area);
    });

    this.houses.forEach(h => {
      totalExclusiveAreas += h.exclusiveArea + h.balconyOver150cm;
      totalServiceAreas += h.balconyLess150cm;
    });

    switch (area) {
      case areaType.exclusiveArea:
        return totalExclusiveAreas.toFixed(2);

      case areaType.serviceArea:
        return totalServiceAreas.toFixed(2);

      case areaType.coreArea:
        return coreAreas.toFixed(2);

      case areaType.buildingArea:
        return (totalExclusiveAreas + totalServiceAreas + coreAreas).toFixed(2);

      case areaType.groundArea:
        return (totalExclusiveAreas + coreAreas).toFixed(2);

      default:
        return 0;
    }
  }

  Switch2D3D = () => {
    if (this.state.is2D) {
      this.sceneManager.switchRenderCamera(!this.state.is2D);

      this.polygon2DGroup.children.forEach(c => {
        c.visible = false;
      })

      this.polygon3DGroup.children.forEach(c => {
        c.visible = true;
      })

      this.setState({
        is2D: false,
      })
    }
    else {
      this.sceneManager.switchRenderCamera(!this.state.is2D);

      this.polygon2DGroup.children.forEach(c => {
        c.visible = true;
      })

      this.polygon3DGroup.children.forEach(c => {
        c.visible = false;
      })

      this.setState({
        is2D: true,
      })
    }
  }

  valueChanged = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    switch (type) {
      case 'exclusive':
        (this.selectingLayer.house as House).exclusiveArea = parseFloat(e.target.value);
        break;
      case 'service':
        (this.selectingLayer.house as House).serviceArea = parseFloat(e.target.value);
        break;
      case 'less150':
        (this.selectingLayer.house as House).balconyLess150cm = parseFloat(e.target.value);
        (this.selectingLayer.house as House).serviceArea = (this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm;
        break;
      case 'over150':
        (this.selectingLayer.house as House).balconyOver150cm = parseFloat(e.target.value);
        (this.selectingLayer.house as House).serviceArea = (this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm;
        break;
      default:
        break;
    }

    this.handled();
  }

  detailButtonClicked = () => {
    (this.selectingLayer.house as House).detialList = !(this.selectingLayer.house as House).detialList;
    this.handled();
  }

  housesListClicked = () => {
    this.housesListHidden = !this.housesListHidden;
    this.handled();
  }

  coreListClicked = () => {
    this.coreListHidden = !this.coreListHidden;
    this.handled();
  }

  saveToAWS = async () => {
    let captureBbox = new THREE.Box3();
    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          l.polygons.forEach(p=>{
            p.vertices.forEach(v=>{
              captureBbox.expandByPoint(v);
            })
          })    
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible;
        })
      })
      resolve();
    })

    let imgBuf = this.sceneManager.getScreenCapture(500, 500, captureBbox);
    this.onWindowResize();

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    let id = uuid4();
    let imageName = `my_building_type_${id}.png`;
    saveDataToS3(imgBuf, "teneleven-platform-my-building-type", imageName, 'image/png');

    let outputValues = getBuildingTypeJson(this.cores);
    console.log(outputValues.templateList[0]);
    let fileName = `my_building_type_image_${id}.json`;
    let outputJson = {
      id: id,
      name: 'My_Type',
      groupTotal: this.cores.length,
      coreTotal: this.cores.length,
      houseTotal: this.houses.length,
      angleRange: [90, -90],
      outline: outputValues.templateList[0].template,
      imageName: imageName,
      allExclusiveArea: this.getAreas(areaType.exclusiveArea),
      allServiceArea: this.getAreas(areaType.serviceArea),
      allCoreArea: this.getAreas(areaType.coreArea),
      allBuildingArea: this.getAreas(areaType.buildingArea),
      allGroundArea: this.getAreas(areaType.groundArea),
    }
    saveDataToS3(JSON.stringify(outputJson), 'teneleven-platform-my-building-type', fileName, 'application/json');

    let data = new Date().toISOString();
    let dbItem: buildingTypeStruct = {
      id: id,
      name: 'test',
      user_id: 'test@1011.co.kr',
      total_exclusive_area: Number(this.getAreas(areaType.exclusiveArea)),
      total_service_area: Number(this.getAreas(areaType.serviceArea)),
      core_area: Number(this.getAreas(areaType.coreArea)),
      building_area: Number(this.getAreas(areaType.buildingArea)),
      floor_area: Number(this.getAreas(areaType.groundArea)),
      houses_number: this.houses.length,
      img_path: imageName,
      file_path: fileName,
      created_at: data,
      modified_at: data,
      deleted: false
    }

    saveDataToDynamoDB(dbItem, 'platform-buildit-my-building-type');
  }

  render = () => {
    let layerDiv = <div />;
    let houseDiv = <div />;
    let houseAreaDiv = <div />;

    if (this.state.layers) {
      layerDiv = <div className='layers' hidden={this.layerListHidden}>
        <ul>{this.state.layers!.map(l =>
          <li key={l.name} onMouseOut={(e) => this.mouseOutLayerTag(e)} onMouseOver={(e) => this.mouseOverLayerTag(e, l)} onClick={() => this.layerClicked(l)}>{`${l.name}`}</li>)}
        </ul>
      </div>
    }

    if (this.state.house) {
      houseDiv = <div className='houses' hidden={this.houseListHidden}>
        <ul>{this.state.house!.map(h =>
          <li key={h.name} onMouseOut={(e) => this.mouseOutHouseTag(e)} onMouseOver={(e) => this.mouseOverHouseTag(e, h)} onClick={(e) => this.addHouse2Core(e, h)}>{h.name}</li>)}
        </ul>
      </div>
    }

    if (!this.layerListHidden && (this.selectingLayer.part === PartOfHouse.wall)) {
      houseAreaDiv = <div className='extraMenu'>
        <div>전용 면적 <input type='number' value={(this.selectingLayer.house as House).exclusiveArea} onChange={(e) => this.valueChanged(e, 'exclusive')} min='0' ></input>㎡</div>
        <div>서비스 면적 <input type='number' value={!(this.selectingLayer.house as House).detialList ? ((this.selectingLayer.house as House).balconyLess150cm + (this.selectingLayer.house as House).balconyOver150cm) : (this.selectingLayer.house as House).serviceArea} onChange={(e) => this.valueChanged(e, 'service')} min='0' disabled={!(this.selectingLayer.house as House).exclusiveArea} ></input>㎡
        <button onClick={this.detailButtonClicked}>{(this.selectingLayer.house as House).detialList ? '+' : '-'}</button></div>
        <div hidden={(this.selectingLayer.house as House).detialList}>1.5m 미만<input type='number' value={(this.selectingLayer.house as House).balconyLess150cm} onChange={(e) => this.valueChanged(e, 'less150')}></input></div>
        <div hidden={(this.selectingLayer.house as House).detialList}>1.5m 이상<input type='number' value={(this.selectingLayer.house as House).balconyOver150cm} onChange={(e) => this.valueChanged(e, 'over150')}></input></div>
      </div>
    }
    else {
      houseAreaDiv = <div></div>;
    }

    return (
      <React.Fragment>
        <header>CAD Converter</header>
        <div className='MainBody'>
          <div className='tablist'>
            <div>
              <input id='fileLable' type="file" onChange={this.loadDXFFile} accept=".dxf" />
            </div>
            <div><span className={this.state.is2D ? '' : 'treeOpen'} onClick={this.housesListClicked}>HOUSES</span></div>
            <div className='list' hidden={this.housesListHidden}>{this.houses.map(h =>
              <div key={h.name}><div>{h.name}<span onClick={() => this.removeHouseFromHouseList(this.houses, this.houses.indexOf(h))}>X</span></div>
                <ul>
                  <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayer(h, PartOfHouse.wall)}>{this.getPolygonLayerName(h.wall, PartOfHouse.wall)}</li>
                  <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayer(h, PartOfHouse.window)}>{this.getPolygonLayerName(h.window, PartOfHouse.window)}</li>
                </ul>
              </div>)}
            </div>
            <button onClick={this.addHouse} disabled={!this.state.is2D}>Add House</button>

            <div><span onClick={this.coreListClicked}>CORES</span></div>
            <div className='list' hidden={this.coreListHidden}>{this.cores.map(c =>
              <div key={c.name}>{c.name}<span onClick={() => this.removeElementFromArray(this.cores as [], this.cores.indexOf(c))}>X</span>
                <ul>
                  <li className={this.state.is2D ? '' : 'li2D'} onClick={() => this.selectLayer(c, PartOfHouse.core)}>{this.getPolygonLayerName(c.core, PartOfHouse.core)}</li>
                  <li><button onClick={() => this.selectLayer(c, PartOfHouse.house)} disabled={!this.state.is2D}>add house</button></li>
                  {c.houses.map(h => <li key={h.name}><div>{h.name}<span onClick={() => this.removeElementFromArray(c.houses as [], c.houses.indexOf(h))}>X</span></div></li>)}
                </ul>
              </div>)}
            </div>
            <button onClick={this.addCore} disabled={!this.state.is2D}>Add Core</button>

            <button onClick={() => this.Switch2D3D()}>2D/3D</button>

            <button onClick={this.saveToAWS} >Save to DB</button>
          </div>
          <div className='RenderView'>
            <div className='information'>
              <div className='info'><div className='infoLabel'>세대수</div><div className='inforValue'>{this.state.house.length}</div></div>
              <div className='info'><div className='infoLabel'>총 전용 면적</div><div className='inforValue'>{this.getAreas(areaType.exclusiveArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>총 서비스 면적</div><div className='inforValue'>{this.getAreas(areaType.serviceArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>코어 면적</div><div className='inforValue'>{this.getAreas(areaType.coreArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>건축 면적</div><div className='inforValue'>{this.getAreas(areaType.buildingArea)}㎡</div></div>
              <div className='info'><div className='infoLabel'>바닥 면적</div><div className='inforValue'>{this.getAreas(areaType.groundArea)}㎡</div></div>
            </div>
            <div className='Scene' ref={(mount) => { this.mount = mount }}>
              {layerDiv}
              {houseDiv}
              {houseAreaDiv}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
